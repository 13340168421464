<template>
    <defaultSec :title="'售后类型管理'">
        <div style="margin-bottom: 10px">
            <el-button type="danger" size="mini" @click="editType({})">添加售后类型</el-button>
        </div>

        <tablePagination :total="0">
            <el-table :data="tableList" border size="mini">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="workOrderTypeCode" label="类型Code"></el-table-column>
                <el-table-column prop="workOrderTypeName" label="类型名称"></el-table-column>
                <el-table-column prop="userNick" label="责任人"></el-table-column>
                <el-table-column label="操作" fixed="right" width="100">
                    <template slot-scope="scope">
                        <el-button type="danger" plain size="mini" @click="editType(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </tablePagination>
        <el-dialog
            title="新增/修改售后类型"
            :visible="editTypeVisible"
            :before-close="handleClose">
            <el-form ref="form" :model="formData" label-width="90px" class="draw-box">
                <el-form-item label="类型Code" required>
                    <el-input v-model="formData.workOrderTypeCode" />
                </el-form-item>
                <el-form-item label="类型名称" required>
                    <el-input v-model="formData.workOrderTypeName" />
                </el-form-item>
                <el-form-item label="责任人">
                    <el-select style="width: 100%" v-model="formData.responsibleUserId" placeholder="请选择">
                        <el-option
                            v-for="item in userList"
                            :key="item.USER_ID"
                            :label="item.USER_NICK"
                            :value="item.USER_ID">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="钉钉部门ID">
                    <el-input v-model="formData.dingdingDeptId" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="orderTypeSubmit">立即保存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
import tablePagination from '@c/tablePagination'
export default {
    name: "workOrderType",/*此处的name必须有且与include中的一致*/
    data() {
        return {
            tableList:[],
            userList:[],
            total:0,
            formData:{},
            editTypeVisible:false
        }
    },
    components:{
        defaultSec,tablePagination
    },
    mounted(){
        this.initList()
    },
    activated(){
    },
    methods: {
        initList() {
            this.$apiGet('lechun-csms/workOrder/workOrderTypeList',{}).then(res => {
                console.log(res)
                this.tableList = res

            })
        },
        initUserList() {
            this.$apiGet('lechun-baseservice/user/getUserListForDorpbox',{}).then(res => {
                console.log(res)
                this.userList = res

            })
        },
        editType(item){
            console.log(item)
            this.formData = item
            this.editTypeVisible = true;
            this.initUserList()
        },
        handleClose(){
            this.editTypeVisible = false;
        },
        orderTypeSubmit(){
            console.log(this.formData)
            this.$apiGet('lechun-csms/workOrder/saveWorkOrderType',this.formData).then(res => {
                console.log(res)
                this.editTypeVisible = false;
                this.initList()
            })
        }
    }
}
</script>


<style scoped lang="scss">
.work-order-detail-info {
    .box-card {
        margin-bottom: 10px;
    }

    .el-card__header {
        padding: 10px;
    }

    .el-card__body {
        padding: 0px;
    }

    .popover-title {
        margin: 0 0 15px 0;
    }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 100%;
    }

    .el-tag {
        margin-right: 10px;
    }
}
</style>